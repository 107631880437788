import { Navigator, Screen } from '@karrotframe/navigator';
import { dataPlugin } from '@karrotframe/plugin';
import { System } from 'kotisaari-component';
import React from 'react';

import { bridge } from '@src/_domain/_shared/utils';
import { RouterPath } from '@src/_router/constants';
import { useDevice } from '@src/_router/hooks';

const AdvertisementBlogPage = React.lazy(() => import('@src/_pages/advertisements/:advertisementId/view'));

/**
 *
 */
export function Router() {
  const device = useDevice();

  return (
    <Navigator
      theme={device}
      useCustomRouter
      plugins={[dataPlugin]}
      onDepthChange={(depth) => {
        bridge.router.style({
          backSwipable: depth <= 0,
          navbar: false,
          scrollable: false,
        });
      }}
      onClose={() => bridge.router.close()}
    >
      {/* 블로그 소재 광고 */}
      <Screen
        path={RouterPath.AdvertisementView}
        component={() => (
          <React.Suspense fallback={<System.FullScreenSpinner />}>
            <AdvertisementBlogPage />
          </React.Suspense>
        )}
      />
    </Navigator>
  );
}
