import { makeExecutableSchema } from '@graphql-tools/schema';
import { graphql, GraphQLSchema } from 'graphql';

import { typeDefs } from '../__codegen__/typeDefs';
import { Context } from '../context';
import { resolvers } from './resolvers';

type QueryArgument = {
  query: string;
  variables?: object;
  context: Context;
};

export interface CreateSchemaOutput {
  schema: GraphQLSchema;
  query: (query: QueryArgument) => Promise<any>;
}

/**
 *
 */
export function createSchema(): CreateSchemaOutput {
  const schema = makeExecutableSchema({
    typeDefs,
    resolvers: resolvers as any,
  });

  /**
   * @param root0
   * @param root0.query
   * @param root0.variables
   * @param root0.context
   */
  function query({ query, variables, context }: QueryArgument): Promise<any> {
    return graphql({
      schema: schema!,
      source: query,
      variableValues: variables,
      contextValue: context,
    });
  }

  return { schema, query };
}
