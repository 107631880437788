import { atom, selector } from 'recoil';

export type HoianUserAccountState = {
  xAuthToken: string;
  data: { id: number; name: string; imageUrl: string; region: { id: number; name: string } };
};

export const hoianUserAccountState = atom<HoianUserAccountState | null>({
  key: 'userAccount/hoianUserAccount',
  default: null,
});

export const hoianUserAuthTokenState = selector({
  key: 'userAccount/hoianUserAuthToken',
  /**
   *
   */
  get({ get }) {
    const hoianUserAccount = get(hoianUserAccountState);
    return hoianUserAccount?.xAuthToken;
  },
});
