import '@ungap/global-this';
import 'core-js/stable';
import React from 'react';
import ReactDOM from 'react-dom';
import { RecoilRoot } from 'recoil';
import 'regenerator-runtime/runtime';

import { App } from './_router/App';

const rootElement = document.getElementById('root');

ReactDOM.render(
  <React.StrictMode>
    <RecoilRoot>
      <App />
    </RecoilRoot>
  </React.StrictMode>,
  rootElement
);
