import { Bridge as BridgeModern } from '@daangn/webview-bridge-modern';
import { PluginAnalytics } from '@daangn/webview-bridge-modern/lib/plugins/Analytics';
import { PluginInfo } from '@daangn/webview-bridge-modern/lib/plugins/Info';
import { PluginPayment } from '@daangn/webview-bridge-modern/lib/plugins/Payment';
import { PluginRouter } from '@daangn/webview-bridge-modern/lib/plugins/Router';
import { PluginShare } from '@daangn/webview-bridge-modern/lib/plugins/Share';
import { PluginToast } from '@daangn/webview-bridge-modern/lib/plugins/Toast';

export const bridge = new BridgeModern()
  .addPlugin(PluginAnalytics)
  .addPlugin(PluginInfo)
  .addPlugin(PluginShare)
  .addPlugin(PluginPayment)
  .addPlugin(PluginRouter)
  .addPlugin(PluginToast);
