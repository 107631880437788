import React from 'react';
import { useSetRecoilState } from 'recoil';

import { IS_DEVELOPMENT } from '@src/_domain/_shared/constants/environment';
import { bridge } from '@src/_domain/_shared/utils';
import { hoianUserAccountState } from '@src/_router/store';

/**
 *
 */
export function useHoianUserSetter() {
  const [loading, setLoading] = React.useState(true);
  const setHoianUserAccount = useSetRecoilState(hoianUserAccountState);

  /**
   * Hoian 유저 정보 저장
   */
  React.useEffect(() => {
    (async () => {
      const user = await bridge.info.user();
      let xAuthToken = user?.authToken;

      if (IS_DEVELOPMENT) {
        xAuthToken = process.env.X_AUTH_TOKEN;
      } else if (!xAuthToken) {
        alert('유저 정보를 불러오지 못했어요.');
      }

      setHoianUserAccount({
        xAuthToken,
        data: {
          id: user?.id ?? 1701,
          name: user?.nickname ?? '댄받아',
          imageUrl:
            user?.profileImage ??
            'https://d1rb42tpk2uaws.cloudfront.net/capri/smb/202011/67babe2a52624a6f1c3c66694a2c9a1696aaf249ba9a26447d.jpeg?q=95&s=1440x1440&t=inside',
          region: { id: user?.region?.id ?? 323, name: user?.region?.name },
        },
      });
      setLoading(false);
    })();
  }, [setHoianUserAccount]);

  return loading;
}
