import { Environment as EnvironmentType } from 'react-relay';
import { Environment, Network, Store } from 'relay-runtime';

import { Context } from './context';
import { createSchema } from './schema/createSchema';

/**
 * @param store
 * @param schema
 * @param context
 */
export function createEnvironment(store: Store, schema: ReturnType<typeof createSchema>, context: Context) {
  return new Environment({
    store,
    network: Network.create(async (operation, variables) => {
      const query = schema.query({
        context,
        variables,
        query: operation.text!,
      });

      console.log(await query);
      return query;
    }),
  }) as unknown as EnvironmentType;
}
