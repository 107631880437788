import to from 'await-to-js';

import { Resolvers } from '../../__codegen__/resolvers';

export const AdvertisementQuery: Resolvers['Query'] = {
  /**
   * @param _
   * @param variables
   * @param root0
   * @param root0.Advertisement
   */
  advertisement: async (_, variables, { KotisaariService, xAuthToken }): Promise<any> => {
    const [error, response] = await to(
      KotisaariService.kotisaariV3ControllerApi.blogAdvertisement(variables.id, xAuthToken)
    );

    if (error || !response?.data.data) throw '광고 정보를 받아오지 못했어요.';
    return response?.data.data;
  },
};
