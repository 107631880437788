import React from 'react';

import { bridge } from '@src/_domain/_shared/utils';

/**
 *
 */
export function useDevice() {
  return React.useMemo(() => {
    switch (bridge.environment) {
      case 'Web':
        return 'Android';
      default:
        return bridge.environment;
    }
  }, []);
}
