import { Resolvers } from '../../__codegen__/resolvers';

export const Advertisement: Resolvers['Advertisement'] = {
  /**
   * @param parent
   */
  id(parent) {
    return `Advertisement#${parent.id}`;
  },
  /**
   * @param parent
   */
  _id(parent) {
    return Number.parseInt(parent.id, 10);
  },
};
