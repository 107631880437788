import to from 'await-to-js';

import { Resolvers } from '../../__codegen__/resolvers';

export const ArticleMutation: Resolvers['Mutation'] = {
  /**
   * @param _
   * @param root0
   * @param root0.input
   * @param root1
   * @param root1.Article
   */
  toggleLikeStatus: async (_, { input }, { KotisaariService, xAuthToken }) => {
    const [error] = await to(
      KotisaariService.kotisaariV2ControllerApi.toggleArticleLike(input.articleId, input.status, xAuthToken)
    );

    if (error) throw '관심 목록을 업데이트하지 못했어요.';

    const [advertisementError, advertisementResponse] = await to(
      KotisaariService.kotisaariV3ControllerApi.blogAdvertisement(input.advertisementId, xAuthToken)
    );

    if (advertisementError || !advertisementResponse?.data.data) throw '광고 정보를 받아오지 못했어요.';
    return advertisementResponse?.data.data;
  },
};
