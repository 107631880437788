import React from 'react';
import { Environment } from 'react-relay';
import { useRecoilValue } from 'recoil';
import { RecordSource, Store } from 'relay-runtime';

import { hoianUserAuthTokenState } from '@src/_router/store';
import { createContext } from '@src/graphql/context';
import { createEnvironment } from '@src/graphql/environment';
import { createSchema, CreateSchemaOutput } from '@src/graphql/schema/createSchema';

/**
 *
 */
export function useRelayEnvironmentCreator() {
  const store = React.useRef(createRelayStore());
  const [environment, setEnvironment] = React.useState<Environment | null>(null);

  const hoianUserAuthToken = useRecoilValue(hoianUserAuthTokenState);

  /**
   * @description store를 바탕으로 Relay Environment를 생성합니다.
   */
  React.useEffect(() => {
    if (!!hoianUserAuthToken) {
      setEnvironment(null);

      const schema: CreateSchemaOutput = createSchema();
      const context = createContext({ xAuthToken: hoianUserAuthToken! });
      setEnvironment(createEnvironment(store.current, schema, context));
    }
  }, [hoianUserAuthToken]);

  return environment;
}

/**
 *
 */
function createRelayStore() {
  const source = new RecordSource();
  return new Store(source);
}
