import { GraphQLDate as Date, GraphQLDateTime as DateTime, GraphQLTime as Time } from 'graphql-iso-date';

import { Resolvers } from '../__codegen__/resolvers';
import { AdvertisementQuery, Advertisement } from './Advertisement';
import { ArticleMutation, Article } from './Article';
import { Query } from './Query';
import { User } from './User';

const scalars: Resolvers = { Date, DateTime, Time };

export const resolvers: Resolvers = {
  Query: {
    ...Query,
    /**
     * 광고
     */
    ...AdvertisementQuery,
  },
  Mutation: {
    /**
     * 게시글
     */
    ...ArticleMutation,
  },
  /**
   * Resolver
   */
  User,
  Article,
  Advertisement,

  /**
   * Scalar
   */
  ...scalars,
};
