import { getKotisaariController, KotisaariController } from '@kotisaari/controller';

import { IS_PRODUCTION } from '@src/_domain/_shared/constants/environment';

export interface CreateContextInput {
  xAuthToken: string;
}

export interface Context extends CreateContextInput {
  xAuthToken: string;
  KotisaariService: KotisaariController;
}

/**
 * @param input
 */
export function createContext(input: CreateContextInput): Context {
  return {
    ...input,
    KotisaariService: getKotisaariController(IS_PRODUCTION),
  };
}
