import { globalStyle, System } from 'kotisaari-component';
import React from 'react';
import { RelayEnvironmentProvider } from 'react-relay';
import { BrowserRouter } from 'react-router-dom';

import { useHoianUserSetter, useRelayEnvironmentCreator } from '@src/_router/hooks';
import { Router } from '@src/_router/Router';
import { customGlobalStyle } from '@src/_router/style';

globalStyle();
customGlobalStyle();

/**
 *
 */
export const App = () => {
  const loadingHoianUserSetter = useHoianUserSetter();
  const relayEnvironment = useRelayEnvironmentCreator();

  if (loadingHoianUserSetter || !relayEnvironment) {
    return <System.FullScreenSpinner />;
  }

  return (
    <React.Suspense fallback={<System.FullScreenSpinner />}>
      <RelayEnvironmentProvider environment={relayEnvironment}>
        <BrowserRouter>
          <Router />
        </BrowserRouter>
      </RelayEnvironmentProvider>
    </React.Suspense>
  );
};
