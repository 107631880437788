import '@karrotframe/navigator/index.css';
import { globalCss } from 'kotisaari-component';
import 'react-quill/dist/quill.snow.css';

export const customGlobalStyle = globalCss({
  '*': {
    wordBreak: 'keep-all',
    '-webkit-tap-highlight-color': 'transparent',
    '&::-webkit-scrollbar': {
      display: 'none',
    },
  },
  '.recharts-cartesian-axis': {
    fontSize: 12,
  },
  '.bar-cell': {
    transition: 'fill 100ms',
  },
  body: {
    margin: '0 auto',
    maxWidth: 768,
  },
});
