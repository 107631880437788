import { Resolvers } from '@src/graphql/__codegen__/resolvers';

export const Article: Resolvers['Article'] = {
  /**
   * @param parent
   */
  id(parent) {
    return `Article#${parent.id}`;
  },
  /**
   * @param parent
   */
  _id(parent) {
    return parent.id;
  },
  /**
   * @param parent
   */
  like(parent) {
    return !!parent.like?.status;
  },
  /**
   * @param parent
   */
  isChat(parent) {
    return !!parent.isChat;
  },
};
