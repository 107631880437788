export const typeDefs = `

input AddLikeArticleInput {
  advertisementId: Int!
  articleId: Int!
  status: Boolean!
}

type Advertisement implements Node {
  _id: Int!
  article: Article!
  categoryNames: String!
  id: ID!
  user: User!
}

type Article implements Node {
  _id: String
  content: String
  createdAt: String
  id: ID!
  isChat: Boolean
  like: Boolean
  phone: String
  price: Int
  reactions: ReactionsType
  title: String!
}

scalar Date

scalar DateTime

interface Edge {
  cursor: String
}

type Mutation {
  """블로그 소재 광고 관심"""
  toggleLikeStatus(input: AddLikeArticleInput!): Advertisement!
}

interface Node {
  id: ID!
}

type PageInfo {
  """종료 커서"""
  endCursor: String

  """다음페이지 있는지 ? 있으면 다음 페이지 요청이 가능"""
  hasNextPage: Boolean!

  """이전 페이지가 있는지 ? 있으면 이전 페이지 요청 가능"""
  hasPreviousPage: Boolean!

  """시작 커서"""
  startCursor: String
}

type Query {
  """광고 1개 조회하기"""
  advertisement(id: Int!): Advertisement!

  """현재 클라이언트 버전"""
  version: String!
}

type ReactionType {
  value: Int
}

type ReactionsType {
  chat: ReactionType
  comment: ReactionType
  follow: ReactionType
  review: ReactionType
  watch: ReactionType
}

scalar Time

type User implements Node {
  """광고주 유니크 아이디"""
  _id: Int!

  """[블로그 소재] 비즈 계정 유저"""
  businessAccountId: String

  """[블로그 소재] 호이안 유저"""
  hoianUserId: String

  """GLOBAL UNIQUE ID"""
  id: ID!

  """광고주의 썸네일 URL"""
  imageUrl: String

  """광고주가 개인 광고주인지 업체 광고주인지"""
  individual: Boolean

  """광고주 이름"""
  name: String!
}

`